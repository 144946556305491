import React from 'react';

const NotFoundPage = () => (
    <>
        <h1> 404: Page Not Found</h1>
        <p> Remind me to add it [SAD FACE HERE]</p>

    </>
);

export default NotFoundPage; 
import React, { useRef, useEffect } from 'react';
import { type, type as loopedType } from '@camwiegert/typical';

const HomePage = () => {
  const myNamesRef = useRef(null);
  
  const myFullName =  "Anthony-Tien Nhat Huynh";
  const myFirstName = "Anthony"
  const myNickName = "Ant"

  const createNameStepWithFullName = (name) => [name, 1000, myFullName, 1000];

  useEffect(() => {
    const stepFirstName = createNameStepWithFullName(myFirstName);
    const stepNickName = createNameStepWithFullName(myNickName);
    const allSteps = [...stepFirstName, 1000, ...stepNickName];

    type(myNamesRef.current, ...allSteps, loopedType);
  }, []);

  return (
    <div className='App'>
      <header className='App-header'>
        <p className='header-text'>
          Hi, my name is <b ref={myNamesRef}></b>
        </p>
      </header>
    </div>
  );
};

export default HomePage;

import React from 'react';

const Certification = () => (
    <>
        <h1> Certifications (Most Recent to Oldest)</h1>
        <p>LinkedIn: JavaScript Essential Training </p>
        <p>LinkedIn: HTML Essential Training </p>
        <p>LinkedIn: Programming Foundations: Object-Oriented Design </p>
        <p>LinkedIn: Succeeding in Web Development: FullStack and Front End </p>
        <p>LinkedIn: UX Foundations: Accessibility </p>
    
        <p>mthree/The Software Guild: Web Development Bootcamp - Java (Full) </p>
        <p>LinkedIn: Learning Cloud Computing: Core Concepts </p>
        <p>LinkedIn: Insights on Software Quality Engineering </p>
    
        <p>HackerRank: Problem Solving (Basic) Certificate</p>

        <p>LinkedIn: React: Creating and Hosting a Full-Stack Site </p>
        <p>LinkedIn: Ethereum: Building Blockchain Decentralized Apps (DApps) </p>
        <p>LinkedIn: Introduction to Web APIs </p>

        <p>LinkedIn: Elasticsearch Essential Training </p>
        <p>AT&T: Summer Learning Academy Extern </p>

        <p>LinkedIn: Cybersecurity Awareness: Security Overview </p>
        <p>HackerRank: Java (Basic) Certificate</p>
    </>
);

export default Certification; 
import React from 'react';
import { Navbar, Nav, DropdownButton, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const TextAnimation = ({ text }) => {
  return (
    <>
      {text.split('').map((letter, index) => (
        <div key={index} className="character">{letter === ' ' ? '\u00A0' : letter}</div>
      ))}
    </>
  );
};

const NavBar = () => (
  <>
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">      
        <TextAnimation text="Anthony-Tien Nhat Huynh" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/certifications">About</Nav.Link>
        </Nav>
        <Nav>
          <DropdownButton
            menuAlign="right"
            title="Profolio"
            id="dropdown-menu-align-right"
            variant="secondary"
          >
            <Dropdown.Item eventKey="1" href="/certifications" >Certifications</Dropdown.Item>
            <Dropdown.Item eventKey="2" href="/projects">Projects</Dropdown.Item>
          </DropdownButton>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
);


export default NavBar;